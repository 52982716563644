package tta.destinigo.talktoastro.core.di


import org.koin.core.context.startKoin
import tta.destinigo.talktoastro.feature_article.di.postFeedModule
import tta.destinigo.talktoastro.feature_authentication.di.authorizationModule
import tta.destinigo.talktoastro.feature_call_consultation.di.callConsultationModule
import tta.destinigo.talktoastro.feature_chat_consultation_new.di.chatConsultationModule
import tta.destinigo.talktoastro.feature_course.di.courseDiModule
import tta.destinigo.talktoastro.feature_custom_order.di.customOrderModel
import tta.destinigo.talktoastro.feature_expert_details.di.expertDetailsCommonModule
import tta.destinigo.talktoastro.feature_expert_listing.di.expertListCommonModule
import tta.destinigo.talktoastro.feature_expert_search.di.searchExpertCommonModule
import tta.destinigo.talktoastro.feature_home.di.homeCommonModule
import tta.destinigo.talktoastro.feature_post.di.PostModel
import tta.destinigo.talktoastro.feature_profile.chat_history.data.di.chatHistoryMode
import tta.destinigo.talktoastro.feature_profile.edit_profile.di.editProfileCommonModule
import tta.destinigo.talktoastro.feature_profile.purchase_history.data.di.PurchaseHistoryModel
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.di.ReferAndEarnModel
import tta.destinigo.talktoastro.feature_profile.transaction_history.data.di.transactionHistoryModel
import tta.destinigo.talktoastro.feature_wallet.di.walletModule

fun initKoin() {
    startKoin {
        modules(
            authorizationModule,
            homeCommonModule,
            courseDiModule,
            expertListCommonModule,
            callConsultationModule,
            chatConsultationModule,
            customOrderModel,
            postFeedModule,
            transactionHistoryModel,
            ReferAndEarnModel,
            editProfileCommonModule,
            expertDetailsCommonModule,
            chatHistoryMode,
            PurchaseHistoryModel,
            PostModel,
            walletModule,
            searchExpertCommonModule
        )
    }
}