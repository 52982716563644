package tta.destinigo.talktoastro.core.navigation_decompose

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.popTo
import com.arkivanov.decompose.router.stack.popToFirst
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.router.stack.pushNew
import com.arkivanov.decompose.router.stack.replaceAll
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.arkivanov.decompose.router.stack.webhistory.WebHistoryController
import com.arkivanov.decompose.value.Value
import tta.destinigo.talktoastro.feature_article.persentation.article_host.ArticleHostComponent
import tta.destinigo.talktoastro.feature_article.persentation.post_page.ArticleComponent
import tta.destinigo.talktoastro.feature_authentication.persentation.login_page.LoginPageComponent
import tta.destinigo.talktoastro.feature_authentication.persentation.otp_validation_page.OtpValidationComponent
import tta.destinigo.talktoastro.feature_authentication.persentation.register_page.RegisterComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_success_page.CallSuccessComponent
import tta.destinigo.talktoastro.feature_call_consultation.persentation.voip_call_page.VoipCallComponent
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_host.decompose.HostDefaultComponent
import tta.destinigo.talktoastro.feature_course.persentation.course_host.HostComponent
import tta.destinigo.talktoastro.feature_custom_order.CustomOrderComponent
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.CustomOrderDetailsComponent
import tta.destinigo.talktoastro.feature_expert_details.expert_detail_page.ExpertDetailsPageComponent
import tta.destinigo.talktoastro.feature_expert_listing.expert_list_page.ExpertListPageComponent
import tta.destinigo.talktoastro.feature_expert_search.SearchScreenComponent
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomePageEvent
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.feature_post.PostDetailsComponent
import tta.destinigo.talktoastro.feature_post.PostScreenComponent
import tta.destinigo.talktoastro.feature_profile.component.ChatConversationComponent
import tta.destinigo.talktoastro.feature_profile.component.ChatHistoryComponent
import tta.destinigo.talktoastro.feature_profile.component.EditProfileComponent
import tta.destinigo.talktoastro.feature_profile.component.MoreScreenEvent
import tta.destinigo.talktoastro.feature_profile.component.MoreScreenTabComponent
import tta.destinigo.talktoastro.feature_profile.component.PrivacyPolicyComponent
import tta.destinigo.talktoastro.feature_profile.component.PrivacyPolicyEvent
import tta.destinigo.talktoastro.feature_profile.component.TermsAndConditionsComponent
import tta.destinigo.talktoastro.feature_profile.component.TermsAndConditionsEvent
import tta.destinigo.talktoastro.feature_profile.purchase_history.PurchaseHistoryComponent
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.ReferAndEarnScreenComponent
import tta.destinigo.talktoastro.feature_profile.transaction_history.persentation.TransactionHistoryComponent
import tta.destinigo.talktoastro.feature_wallet.persentation.all_coupons_page.AllCouponComponent
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_checkout.RechargeCheckoutComponent
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_failure.RechargeFailureComponent
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_getway.PaymentGatewayComponent
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_pack.ChoseRechargePackComponent
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_payment_success.RechargeSuccessComponent
import tta.destinigo.talktoastro.shared.config.COURSES_LIST
import tta.destinigo.talktoastro.shared.config.CUSTOM_ORDER_LIST
import tta.destinigo.talktoastro.shared.data.CommonExpertInfo

class DefaultRootComponent @OptIn(
    ExperimentalDecomposeApi::class,
    ExperimentalDecomposeApi::class
) constructor(
    private val componentContext: ComponentContext,
    private val deepLink: DeepLink.Web,
    private val webHistoryController: WebHistoryController? = null,
) : RootComponent, ComponentContext by componentContext {

    private val navigation = StackNavigation<Configuration>()

    @OptIn(ExperimentalDecomposeApi::class)
    private val _stack =
        childStack(
            source = navigation,
            serializer = Configuration.serializer(),
            initialStack = {
                getInitialStack(
                    webHistoryPaths = webHistoryController?.historyPaths,
                    deepLink = deepLink
                )
            },
            childFactory = ::createChild,
            handleBackButton = true
        )

    override val stack: Value<ChildStack<*, RootComponent.Child>> = _stack

    init {
        // Attach the WebHistoryController to sync the browser's history with your Decompose navigation stack
        webHistoryController?.attach(
            navigator = navigation,
            serializer = Configuration.serializer(),
            stack = _stack,
            getPath = Companion::getPathForConfig,
            getConfiguration = Companion::getConfigForPath,
            onWebNavigation = { previousStack, currentStack ->
                try {
                    when {
                        previousStack.size > currentStack.size -> {
                            println("User navigated back in web history.")
                        }

                        previousStack.size < currentStack.size -> {
                            println("User navigated forward in web history.")
                        }

                        else -> {
                            println("User refreshed or navigated to the same page.")
                        }
                    }
                } catch (e: IndexOutOfBoundsException) {
                    println("Index out of bounds error during navigation: ${e.message}")
                } catch (e: NullPointerException) {
                    println("Null pointer error during navigation: ${e.message}")
                } catch (e: Exception) {
                    println("General error during navigation: ${e.message}")
                }

                true
            }
        )
    }

    @OptIn(ExperimentalDecomposeApi::class)
    private fun createChild(
        configuration: Configuration,
        context: ComponentContext
    ): RootComponent.Child {
        return when (configuration) {

            Configuration.MoreScreenTab -> RootComponent.Child.MoreScreenTab(
                MoreScreenTabComponent(
                    context = context,
                    onMoreScreenEvent = { moreScreenEvent ->
                        when (moreScreenEvent) {
                            MoreScreenEvent.onClickWalletBlance -> navigation.push(Configuration.ChoseRechargePage)
                            MoreScreenEvent.onPricayPolicyClick -> navigation.push(Configuration.PrivacyPolicyPage)
                            MoreScreenEvent.onTermAndConditionClick -> navigation.push(Configuration.TermsAndConditions)
                            MoreScreenEvent.onClickEditPrifile -> navigation.push(Configuration.EditProfileScreen)
                            MoreScreenEvent.onBackButtonClick -> navigation.pop()
                            MoreScreenEvent.onReferAndEarnClick -> navigation.pushNew(Configuration.ReferAndEarnConfig)
                            MoreScreenEvent.onTransactionHistoryScreenClick -> navigation.pushNew(
                                Configuration.TransactionHistoryComponent
                            )

                            MoreScreenEvent.onSignOutButtonClick -> {
                                navigation.popTo(0)
                            }

                            MoreScreenEvent.onWalletButtonClick -> navigation.pushNew(Configuration.ChoseRechargePage)
                            MoreScreenEvent.onChatHistoryClick -> navigation.pushNew(Configuration.ChatHistory)
                            MoreScreenEvent.onPurchaseHistoryClick -> navigation.pushNew(
                                Configuration.PurchaseHistoryConfig
                            )
                        }
                    }
                )
            )

            Configuration.MoreScreenTab -> TODO()

            is Configuration.LoginPage -> RootComponent.Child.LoginPage(
                LoginPageComponent(
                    context = context,
                    navigateToOtpValidationPage = { firstString, secondString, isWhatsappUpdatesChecked ->
                        navigation.pushNew(
                            Configuration.OtpValidationPage(
                                firstString,
                                secondString,
                                isWhatsappUpdatesChecked
                            )
                        )
                    },
                    onPrivacyPolicyClick = {
                        navigation.pushNew(Configuration.TermsAndConditions)
                    }
                )
            )
//
            is Configuration.OtpValidationPage -> RootComponent.Child.OtpValidationPage(
                OtpValidationComponent(
                    mobileNumber = configuration.mobileNumber,
                    mobileCode = configuration.mobileCode,
                    isWhatsappUpdatesChecked = false,
                    componentContext = context,
                    onRegisterEvent = {
                        navigation.pushNew(Configuration.RegistrationPage)
                    },
                    onDashboardEvent = {
                        navigation.replaceAll(Configuration.HomeScreenTab())
                    }
                )
            )

            is Configuration.RegistrationPage -> RootComponent.Child.RegistrationPage(
                RegisterComponent(
                    context = context,
                    onRegistration = {
                        navigation.replaceAll(Configuration.HomeScreenTab())
                    }
                )
            )
//
            is Configuration.ExpertListPage -> RootComponent.Child.ExpertListPage(
                ExpertListPageComponent(
                    context = context,
                    categoryId = configuration.categoryId,
                    categoryName = configuration.categoryName,
                    subCategoriesGiven = configuration.subCategory,
                    onExpertListPageEvent = { navigation.push(Configuration.ExpertDetailsPage(configuration.categoryId.toInt())) },
                    onBackPressed = { navigation.pop() },
                    onSearchFieldClick = { navigation.push(Configuration.DisplaySearchExpertList) },
                    onClickExpertCard = { expertId ->
                        navigation.pushNew(
                            Configuration.ExpertDetailsPage(
                                expertId
                            )
                        )
                    },
                    onCallButtonClick = { expertId, expertName, expertImage, expertSkills ->
                        navigation.pushNew(
                            Configuration.CallWaitingSuccessComponent(
                                expertId = expertId,
                                expertName = expertName,
                                expertImage = expertImage,
                                expertSkills = expertSkills,
                                callType = ""
                            )
                        )
                    },
                    onChatButtonClick = { id: String, name: String, image: String, skill: String, duration: String, category: String ->
                        navigation.pushNew(
                            Configuration.ChatConcernPage(
                                expertId = id,
                                expertName = name,
                                expertImage = image,
                                expertSkills = skill,
                                chatDuration = duration,
                                category = category,
                                chatType = ""
                            )
                        )
                    },
                    onLoginClick = { navigation.pushNew(Configuration.LoginPage) },
                    onRechargeRequired = { rechargeData ->
                        if (rechargeData == null) {
                            navigation.pushNew(Configuration.ChoseRechargePage)
                            return@ExpertListPageComponent
                        }
                        navigation.push(
                            Configuration.RechargeCheckout(
                                rechargeData = rechargeData
                            )
                        )
                    },
                    onVoIPCallConsultationStart = { duration, expertInfo ->
                        navigation.pushNew(
                            Configuration.VoIPCallConfig(
                                expertInfo = expertInfo,
                                callDuration = duration,
                            )
                        )

                    },
                    onLowBalance = {
                        navigation.pushNew(Configuration.ChoseRechargePage)
                    }
                )
            )

            is Configuration.ExpertDetailsPage -> RootComponent.Child.ExpertDetailsPage(
                ExpertDetailsPageComponent(
                    context = context,
                    expertId = configuration.expertId,
                    onBackPressed = {
                        navigation.pop()
                    },
                    onCallButtonClick = { expertId, expertName, expertImage, expertSkills, isEmergency ->
                        navigation.pushNew(
                            Configuration.CallWaitingSuccessComponent(
                                expertId = expertId,
                                expertName = expertName,
                                expertImage = expertImage,
                                expertSkills = expertSkills,
                                callType = isEmergency
                            )
                        )
                    },
                    onLoginClick = { navigation.pushNew(Configuration.LoginPage) },
                    onClickRelatedExpert = { expertId ->
                        navigation.pushNew(
                            Configuration.ExpertDetailsPage(
                                expertId
                            )
                        )
                    },
                    onRechargeAgain = { rechargeData ->
                        if (rechargeData == null) {
                            navigation.pushNew(Configuration.ChoseRechargePage)
                            return@ExpertDetailsPageComponent
                        }
                        navigation.push(
                            Configuration.RechargeCheckout(
                                rechargeData = rechargeData
                            )
                        )

                    },
                    onChatStartClick = { id, name, image, skill, duration, category, isEmergency ->
                        navigation.push(
                            Configuration.ChatConcernPage(
                                expertId = id,
                                expertName = name,
                                expertImage = image,
                                expertSkills = skill,
                                chatDuration = duration,
                                category = category,
                                chatType = isEmergency

                            )
                        )
                    },
                    onVoIPCallConsultationStart = { duration, expertInfo ->
                        navigation.pushNew(
                            Configuration.VoIPCallConfig(expertInfo, duration)
                        )
                    },
                    onLowBalance = { navigation.pushNew(Configuration.ChoseRechargePage) }
                )
            )

            is Configuration.ChoseRechargePage -> RootComponent.Child.ChoseRechargePage(
                ChoseRechargePackComponent(
                    context = context,
                    onBackPressed = { navigation.pop() },
                    navigateToRechargeCheckout = {
                        navigation.pushNew(Configuration.RechargeCheckout(it))
                    }
                )
            )

            is Configuration.CourseHostConfig -> RootComponent.Child.CourseHostChild(
                HostComponent(
                    componentContext = context,
                    deepLink = deepLink.path,
                    webHistoryController = webHistoryController,
                    onClose = {
                        navigation.replaceAll(Configuration.HomeScreenTab())
                        //navigation.pop()
                    }
                )
            )

            is Configuration.HomeScreenTab -> RootComponent.Child.HomeScreenTab(
                HomeScreenTabComponent(
                    context = context,
                    onHomePageEvent = { event ->
                        when (event) {
                            is HomePageEvent.OnClickTopConsultant -> {
                                navigation.push(Configuration.ExpertDetailsPage(event.expertCategory))
                            }

                            is HomePageEvent.OnClickCategory -> {
                                when (event.category?.name) {
                                    COURSES_LIST -> {
                                        // Navigate to the LandingPage specific for Scientific Astrology
                                        navigation.push(Configuration.CourseHostConfig)
                                    }

                                    CUSTOM_ORDER_LIST -> {
                                        // Navigate to the CustomOrderPage
                                        Logger.d { "Navigate to CustomOrderPage" }
                                        navigation.push(Configuration.CustomOrderPage)
                                    }

                                    else -> {
                                        // Navigate to ExpertListPage for other categories
                                        navigation.push(
                                            Configuration.ExpertListPage(
                                                event.category?.name ?: "Expert List",
                                                event.category?.id.toString(),
                                                subCategory = event.category
                                            )
                                        )
                                    }
                                }
                            }


                            is HomePageEvent.OnLiveSessionClick -> Unit
                            is HomePageEvent.OnSearchClick -> navigation.pushNew(Configuration.DisplaySearchExpertList)
                            is HomePageEvent.OnClickLogin -> navigation.push(Configuration.LoginPage)
                            HomePageEvent.OnClickProfileIcon -> navigation.push(Configuration.MoreScreenTab)
                            HomePageEvent.OnClickPrivacyPolicy -> navigation.push(Configuration.PrivacyPolicyPage)
                            HomePageEvent.OnClickTermsAndConditions -> navigation.push(Configuration.TermsAndConditions)
                            HomePageEvent.OnClickWalletIcon -> navigation.push(Configuration.ChoseRechargePage)
                            is HomePageEvent.OnChatConsultationStart -> navigation.push(
                                Configuration.ChatConcernPage(
                                    expertId = event.consultantId.toString(),
                                    expertName = event.consultantName,
                                    expertImage = event.consultantImage,
                                    expertSkills = event.consultantSkills,
                                    chatDuration = event.chatDuration,
                                    category = event.category,
                                    chatType = ""
                                )
                            )

                            is HomePageEvent.OnCallConsultationStart -> navigation.push(
                                Configuration.CallWaitingSuccessComponent(
                                    callType = event.callType,
                                    expertName = event.consultantName,
                                    expertId = event.consultantId.toString(),
                                    expertSkills = event.consultantSkills,
                                    expertImage = event.consultantImage
                                )
                            )

                            is HomePageEvent.OnRequiredRecharge -> {
                                if (event.rechargeData == null) {
                                    navigation.pushNew(Configuration.ChoseRechargePage)
                                    return@HomeScreenTabComponent
                                }
                                navigation.push(
                                    Configuration.RechargeCheckout(
                                        rechargeData = event.rechargeData
                                    )
                                )
                            }

                            HomePageEvent.onLandingPageClick -> {
//                                navigation.pushNew(Configuration.CoursesConfig)
//                                navigation.pushNew(Configuration.LandingPage)
                            }

//                            is HomePageEvent.OnVoIPCallConsultationStart -> navigation.pushNew(
//                                Configuration.VoIPCallConfig(
//                                    expertInfo = event.expertInfo,
//                                    callDuration = event.duration
//
//                                )
//                            )

                            // is HomePageEvent.OnVoIPCallConsultationStart -> TODO()

                            //is HomePageEvent.OnNavigateCallWaitingPage -> TODO()
                            is HomePageEvent.OnArticleClick -> {
                                Logger.d { "ArticleHostChild Called" }
                                navigation.pushNew(Configuration.ArticleHostChild)
                            }
                            is HomePageEvent.OnClickPostSeeAll -> navigation.pushNew(
                                Configuration.PostScreenConfig
                            )

                            is HomePageEvent.OnClickReadPost -> {
                                navigation.pushNew(
                                    Configuration.PostDetailsConfig(event.id)
                                )
                            }
                        }
                    }
                )
            )

//            is Configuration.MainScreen -> RootComponent.Child.MainScreen(
//                MainScreenComponent(context = context) { event ->
////                    handleNavigationEvent(event)
//
//                }
//            )

            is Configuration.PrivacyPolicyPage -> RootComponent.Child.PrivacyPolicyPage(
                PrivacyPolicyComponent(context = context) { privacyPolicyEvent ->
                    when (privacyPolicyEvent) {
                        PrivacyPolicyEvent.OnBackKeyPress -> navigation.pop()
                    }

                }
            )

            is Configuration.TermsAndConditions -> RootComponent.Child.TermsAndConditions(
                TermsAndConditionsComponent(context = context) { termsAndConditionsEvent ->
                    when (termsAndConditionsEvent) {
                        TermsAndConditionsEvent.OnBackKeyPress -> navigation.pop()
                    }
                }
            )

            is Configuration.EditProfileScreen -> RootComponent.Child.EditProfileScreen(
                EditProfileComponent(context = context, onBackPress = {
                    navigation.pop()
                })
            )

            is Configuration.RechargeCheckout -> RootComponent.Child.RechargeCheckout(
                RechargeCheckoutComponent(
                    context = context,
                    _rechargeData = configuration.rechargeData,
                    onProceedToPayment = { paymentGatewayArg ->
                        navigation.replaceCurrent(
                            Configuration.PaymentGateway(
                                paymentGatewayArg,
                                isCustomOrder = configuration.isCustomOrder
                            )
                        )
                    },
                    onBackPressed = { navigation.pop() },
                    onShowAllCouponClick = {
                        navigation.push(Configuration.AllCouponCode)
                    },
                    context1 = context,
                    _isCustomOrder = configuration.isCustomOrder,
                    _heading = configuration.orderHeading,
                    _image = configuration.image,
                    customOrderConfirm = { customOrderId ->
                        navigation.replaceCurrent(
                            Configuration.CustomOrderPaymentConfirm(
                                customOrderId = customOrderId
                            )
                        )
                    },
                    customOrderError = { navigation.replaceCurrent(Configuration.CustomOrderPaymentError) }
                    //_selectedCouponCode = selectedCouponCode!!,
                ),
                AllCouponComponent(
                    onBackPressed = { navigation.pop() },
                    context = context
                )
            )

            is Configuration.PaymentGateway -> RootComponent.Child.PaymentGateway(
                PaymentGatewayComponent(
                    context = context,
                    paymentGatewayArg = configuration.paymentGatewayArg,
                    onRechargeSuccess = { topUpAmount, customOrderId ->
                        if (topUpAmount.equals("CustomOrder", true) && !customOrderId.isEmpty()) {
                            navigation.replaceCurrent(
                                Configuration.CustomOrderPaymentConfirm(
                                    customOrderId = customOrderId
                                )
                            )
                        } else {
                            navigation.replaceCurrent(
                                Configuration.RechargeSuccess(
                                    topUpAmount
                                )
                            )
                        }
                    },
                    _isCustomOrder = configuration.isCustomOrder,
                    onRechargeFailed = { topUpAmount ->
                        //Logger.d { "onRechargeFailed navigate" }
                        if (topUpAmount.equals("CustomOrder", true)) {
                            navigation.replaceCurrent(Configuration.CustomOrderPaymentError)
                        } else {
                            navigation.replaceCurrent(Configuration.RechargeFailure(topUpAmount = topUpAmount))
                        }


                    }
                )
            )
//
            is Configuration.RechargeFailure -> {
                RootComponent.Child.RechargeFailure(
                    RechargeFailureComponent(
                        context = context,
                        _topUpAmount = configuration.topUpAmount,
                        rechargeAgain = {
                            navigation.pop()
                        },
                        getSupport = {
                            navigation.popToFirst()
                        }
                    )
                )
            }
//
            is Configuration.RechargeSuccess -> {
                RootComponent.Child.RechargeSuccess(
                    RechargeSuccessComponent(
                        context = context,
                        _topUpAmount = configuration.topUpAmount,
                        onDone = {
                            navigation.replaceAll(Configuration.HomeScreenTab())
                        },
                        rechargeMore = {
                            navigation.pop()
                        }
                    )
                )
            }

            is Configuration.DisplaySearchExpertList -> {
                RootComponent.Child.DisplaySearchExpertList(
                    component = SearchScreenComponent(
                        context = context,
                        onBackPress = { navigation.pop() },
                        onClickExpertCard = { expertId ->
                            navigation.pushNew(
                                Configuration.ExpertDetailsPage(
                                    expertId
                                )
                            )
                        }
                    )
                )
            }


            is Configuration.ChatConcernPage -> {
                RootComponent.Child.ChatHostChild(

                    HostDefaultComponent(
                        expertId = configuration.expertId,
                        expertName = configuration.expertName,
                        expertImage = configuration.expertImage,
                        expertSkills = configuration.expertSkills,
                        chatDuration = configuration.chatDuration,
                        componentContext = context,
                        onClose = { navigation.pop() },
                        category = configuration.category,
                        chatType = configuration.chatType
                    )
                )
            }


            is Configuration.CallPlaceSuccessval -> RootComponent.Child.CallPlaceSuccess(
                component = CallSuccessComponent(
                    context = context,
                    _expertId = configuration.expertId,
                    _expertName = configuration.expertName,
                    _expertSkills = configuration.expertSkills,
                    _expertImage = configuration.expertImage,
                    onClosePage = {
                        navigation.popTo(0)
                    },
                    onTimeOutOver = {
                        navigation.replaceAll(Configuration.HomeScreenTab())
                    },
                    _callType = configuration.callType
                )
            )


            is Configuration.CallWaitingSuccessComponent -> RootComponent.Child.CallWaitingSuccess(
                CallSuccessComponent(
                    context = context,
                    _expertId = configuration.expertId,
                    _expertName = configuration.expertName,
                    _expertImage = configuration.expertImage,
                    _expertSkills = configuration.expertSkills,
                    onClosePage = { navigation.popTo(0) },
                    onTimeOutOver = { navigation.replaceAll(Configuration.HomeScreenTab()) },
                    _callType = configuration.callType
                )
            )

//            is Configuration.LandingPage -> RootComponent.Child.LandingPage(
//                LandingPageComponent(
//                    context = context,
//                    onBackButtonClick = { navigation.pop() },
//                ),
//                HomeScreenTabComponent(
//                    context = context,
//                    onHomePageEvent = { event ->
//                        when (event) {
//                            is HomePageEvent.OnClickTopConsultant -> {
//                                navigation.push(Configuration.ExpertDetailsPage(event.expertCategory))
//                            }
//
//                            is HomePageEvent.OnClickCategory -> {
//                                navigation.push(
//                                    Configuration.ExpertListPage(
//                                        event.category.name,
//                                        event.category.id.toString(),
//                                        event.category.subcategories!!
//                                    )
//                                )
//                            }
//
//                            is HomePageEvent.OnLiveSessionClick -> Unit
//                            is HomePageEvent.OnSearchClick -> Unit
//                            //is HomePageEvent.OnSearchClick -> navigation.pushNew(Configuration.ArticleHostConfig)
//                            is HomePageEvent.OnClickLogin -> navigation.push(Configuration.LoginPage)
//                            HomePageEvent.OnClickProfileIcon -> navigation.push(Configuration.MoreScreenTab)
//                            HomePageEvent.OnClickPrivacyPolicy -> navigation.push(Configuration.PrivacyPolicyPage)
//                            HomePageEvent.OnClickTermsAndConditions -> navigation.push(Configuration.TermsAndConditions)
//                            HomePageEvent.OnClickWalletIcon -> navigation.push(Configuration.ChoseRechargePage)
//                            is HomePageEvent.OnChatConsultationStart -> navigation.push(
//                                Configuration.ChatConcernPage(
//                                    expertId = event.consultantId.toString(),
//                                    expertName = event.consultantName,
//                                    expertImage = event.consultantImage,
//                                    expertSkills = event.consultantSkills,
//                                    chatDuration = event.chatDuration,
//                                    category = event.category,
//                                    chatType = ""
//                                )
//                            )
//
//                            is HomePageEvent.OnCallConsultationStart -> navigation.pushNew(
//                                Configuration.CallWaitingSuccessComponent(
//                                    expertId = event.consultantId.toString(),
//                                    expertName = event.consultantName,
//                                    expertImage = event.consultantImage,
//                                    expertSkills = event.consultantSkills,
//                                    callType = event.callType
//                                    //  chatDuration = event.chatDuration,
////                                    event.consultantId.toString(),
////                                    event.consultantName,
////                                    event.consultantImage,
////                                    event.consultantSkills,
//                                )
//                            )
//
//                            is HomePageEvent.OnRequiredRecharge -> {
////                                navigation.push(
////                                    Configuration.RechargeCheckout(
////                                        rechargeData = event.rechargeData
////                                    )
////                                )
//                            }
//
//                            is HomePageEvent.OnArticleClick -> navigation.pushNew(Configuration.ArticleHostConfig)
//
//                            HomePageEvent.onLandingPageClick -> navigation.pushNew(Configuration.LandingPage)
//                            is HomePageEvent.OnVoIPCallConsultationStart -> TODO()
////                            is HomePageEvent.OnVoIPCallConsultationStart -> navigation.pushNew(
////                                Configuration.VoIPCallConfig(
////                                    expertInfo = event.expertInfo,
////                                    callDuration = event.duration
////                                )
////                            )
//
//                            is HomePageEvent.OnNavigateCallWaitingPage -> navigation.pushNew(
//                                Configuration.CallPlaceSuccessval(
//                                    expertId = "",
//                                    expertName = "",
//                                    expertImage = "",
//                                    expertSkills = "",
//                                    callType = ""
//                                )
//                            )
//
//                            is HomePageEvent.OnClickPostSeeAll -> navigation.pushNew(
//                                Configuration.PostScreenConfig
//                            )
//
//                            is HomePageEvent.OnClickReadPost -> {
//                                navigation.pushNew(
//                                    Configuration.PostDetailsConfig(event.id)
//                                )
//                            }
//                        }
//                    }
//                )
//            )

            is Configuration.TransactionHistoryComponent -> RootComponent.Child.TransactionHistory(
                TransactionHistoryComponent(
                    context = context,
                    onBackButtonClick = { navigation.pop() }
                )
            )

            is Configuration.VoIPCallConfig -> RootComponent.Child.VoIPCall(
                VoipCallComponent(
                    context = context,

                    expertInfo = configuration.expertInfo,
                    _callDuration = configuration.callDuration,
                    onClose = {
                        navigation.pop()
                    },
                )
            )

            //  is Configuration.VoIPCallConfig -> TODO()

//            is Configuration.AllCouponCode -> RootComponent.Child.DisplayAllCouponCode(
//                AllCouponComponent(
//                    context = context,
//                    onBackPressed = {
//                        navigation.pop()
//                    }
//                )
//            )

            is Configuration.ChatHistory -> RootComponent.Child.ChatHistory(
                ChatHistoryComponent(
                    context = context,
                    onBackPress = { navigation.pop() },
                    onChatCardClick = { chatId, name, image, duration ->
                        navigation.pushNew(
                            Configuration.ChatConversationScreen(
                                chatId,
                                name,
                                image,
                                duration
                            )
                        )
                    },
                    onLowBalance = {
                        navigation.pushNew(Configuration.ChoseRechargePage)
                    },
                    onChatConsultation = { expertId, name, image, skill, duration, category,chatType ->
                        //navigation.pushNew(Configuration.)

                        navigation.pushNew(
                            Configuration.ChatConcernPage(
                                expertId = expertId,
                                expertName = name,
                                expertImage = image,
                                expertSkills = skill,
                                chatDuration = duration,
                                category = category,
                                chatType = chatType
                            )
                        )
                    },
                    onRechargeRequired = { data ->
                        if (data == null) {
                            navigation.pushNew(Configuration.ChoseRechargePage)
                            return@ChatHistoryComponent
                        }
//                        navigation.push(
////                            Configuration.RechargeCheckout(
////                                rechargeData = data
////                            )
//                        )
                    },
                    onLoginClick = {}
                ),
                ExpertDetailsPageComponent(
                    context = context,
                    expertId = 0,
                    onBackPressed = {},
                    onCallButtonClick = { s: String, s1: String, s2: String, s3: String, s4:String -> },
                    onLoginClick = {},
                    onClickRelatedExpert = {},
                    onChatStartClick = { s: String, s1: String, s2: String, s3: String, s4: String, s5: String, s6:String -> },
                    onRechargeAgain = {},
                    onVoIPCallConsultationStart = { s: String, commonExpertInfo: CommonExpertInfo -> },
                    onLowBalance = { navigation.pushNew(Configuration.ChoseRechargePage) }
                )
            )


            is Configuration.ChatConversationScreen -> RootComponent.Child.ChatConversationScreen(
                ChatConversationComponent(
                    context = context,
                    onBackPress = { navigation.pop() },
                    _chatId = configuration.chatId,
                    _expertName = configuration.name,
                    _expertImage = configuration.image,
                    _chatDuration = configuration.duration
                )
            )
            is Configuration.ArticleDetailPageConfig -> RootComponent.Child.ArticleDetailPage(
                ArticleComponent(
                    componentContext = context,
                    slug = configuration.slug,
                    onClose = { navigation.pop() }
                )
            )
//
            is Configuration.CustomOrderPage -> RootComponent.Child.CustomOrderPage(
                CustomOrderComponent(
                    context = context,
                    onBackPress = { navigation.pop() },
                    onCradClick = { data ->
                        navigation.pushNew(Configuration.CustomOrderDetails(data = data))
                    }
                )
            )

            is Configuration.CustomOrderDetails -> RootComponent.Child.CustomOrderDetails(
                CustomOrderDetailsComponent(
                    context = context,
                    onBackPress = { navigation.pop() },
                    _data = configuration.data,
                    onEnrollNowClick = { data, image, heading ->
//                        navigation.pushNew(
//                            Configuration.RechargeCheckout(
//                                rechargeData = data,
//                                isCustomOrder = true,
//                                image = image,
//                                orderHeading = heading
//                            )
//                        )

                    },
                    onLoginClick = { navigation.pushNew(Configuration.LoginPage) }
                )
            )

//            is Configuration.CoursesConfig -> RootComponent.Child.Courses(
//                CoursesComponent(
//                    context = context,
//                    onBackButtonClick = {
//                        navigation.pop()
//                    },
//                    onRegisterButtonClick = { courseData ->
//                        navigation.pushNew(
//                            Configuration.CourseConfig(
//                                slug = courseData
//                            )
//                        )
//                    })
//            )

//            is Configuration.CoursesConfig -> TODO()

//            is Configuration.CourseConfig -> RootComponent.Child.Course(
//                CourseComponent(
//                    context = context,
//                    slug = configuration.slug
//                )
//            )
//
//            is Configuration.CourseConfig -> TODO()


//            is Configuration.CustomOrderPaymentConfirm -> {
//                RootComponent.Child.CustomOrderConfirmation(
//                    EnrollCustomOrderConfirmationBottomSheetComponent(
//                        context = context,
//                        onBackPress = { navigation.pop() },
//                        onClickLesson = { customOrderId ->
//                            navigation.pushNew(Configuration.CustomOrderLesson(customOrderId = customOrderId))
//                        },
//                        _customOrderId = configuration.customOrderId
//                    )
//                )
//            }

//            is Configuration.CustomOrderLesson -> {
//                RootComponent.Child.CustomOrderLesson(
//                    CustomOrderLessonComponent(
//                        context = context,
//                        onBackPress = { navigation.pop() },
//                        _customOrderId = configuration.customOrderId
//                    )
//                )
//            }
//
//            is Configuration.CustomOrderPaymentError -> {
//                RootComponent.Child.CustomOrderError(
//                    EnrolErrorBottomSheetComponent(
//                        context = context,
//                        onBackPress = { navigation.pop() }
//                    )
//                )
//            }

            is Configuration.PurchaseHistoryConfig -> {
                RootComponent.Child.PurchaseHistory(
                    PurchaseHistoryComponent(
                        context = context,
                        onBack = { navigation.pop() },
                        onPurchaseHistoryCardClick = { data ->
                            navigation.pushNew(Configuration.CustomOrderLesson(data))
                        }
                    )
                )
            }

            is Configuration.ReferAndEarnConfig -> {
                RootComponent.Child.ReferAndEarn(
                    ReferAndEarnScreenComponent(
                        context = context,
                        onBackButtonClick = { navigation.pop() }
                    )
                )
            }

            is Configuration.PostScreenConfig -> {
                RootComponent.Child.PostScreen(
                    PostScreenComponent(
                        context = context,
                        onBackClick = { navigation.pop() },
                        onPostClick = { postData->
                            navigation.pushNew(Configuration.PostDetailsConfig(id = postData))
                            //Logger.d { "Post Click Data $postData" }
                        }
                    )
                )
            }


            is Configuration.PostDetailsConfig -> {
                RootComponent.Child.PostDetailsScreenChild(
                    PostDetailsComponent(
                        context = context,
                        onBackClick = { navigation.pop() },
                        _postData = configuration.id
                    )
                )
            }

            is Configuration.MainScreen -> TODO()
            Configuration.EditProfileScreen -> TODO()
            Configuration.LandingPage -> TODO()
            Configuration.PrivacyPolicyPage -> TODO()
            Configuration.PurchaseHistoryConfig -> TODO()
            Configuration.ReferAndEarnConfig -> TODO()
            Configuration.TermsAndConditions -> TODO()
            Configuration.TransactionHistoryComponent -> TODO()
            Configuration.AllCouponCode -> TODO()
            is Configuration.CustomOrderLesson -> TODO()
            Configuration.CustomOrderPage -> TODO()
            is Configuration.CustomOrderPaymentConfirm -> TODO()
            Configuration.CustomOrderPaymentError -> TODO()
            Configuration.LoginPage -> TODO()
//            is Configuration.RechargeCheckout -> TODO()
            Configuration.RegistrationPage -> TODO()
            // is Configuration.RechargeCheckout -> TODO()
            Configuration.ArticleHostChild ->{
                Logger.d { "ArticleHostChild Called" }
                RootComponent.Child.ArticleHostChild(
                    ArticleHostComponent(
                        componentContext = context,
                        deepLink = deepLink.path,
                        webHistoryController = webHistoryController,
                        onClose = {
                            navigation.replaceAll(Configuration.HomeScreenTab())
                            //navigation.pop()
                        }
                    )
                )
            }
        }
    }

    override fun onBackClicked() {
        navigation.pop()
    }

    override fun onBackClicked(toIndex: Int) {
        navigation.popTo(index = toIndex)
    }

    private companion object {


//        private fun getInitialStack(
//            webHistoryPaths: List<String>?,
//            deepLink: DeepLink
//        ): List<Configuration> {
//            // Convert web history paths to configurations if available
//            val historyConfigs = webHistoryPaths
//                ?.filterNot { it.isEmpty() } // Filter out any empty paths
//                ?.map(Companion::getConfigForPath) // Only map to non-null configurations
//                ?.distinct() // Ensure no duplicate configurations
//                ?: emptyList()
//
//            // If historyConfigs is not empty, return it; otherwise, fallback to deep link logic
//            return historyConfigs.ifEmpty {
//                getInitialStack(deepLink)
//            }
//        }

        private fun getInitialStack(
            webHistoryPaths: List<String>?,
            deepLink: DeepLink
        ): List<Configuration> {
            // Convert web history paths to configurations if available

            //Logger.d("getInitialStack -> webHistoryPaths -> $webHistoryPaths")
            //Logger.d("getInitialStack -> deep Links -> $deepLink")

            val historyConfigs = webHistoryPaths
                ?.filterNot { it.isEmpty() } // Filter out any empty paths
                ?.mapNotNull(Companion::getConfigForPath) // Use mapNotNull to filter out null configurations
                ?.distinct() // Ensure no duplicate configurations
                ?: emptyList()

            // If historyConfigs is not empty, return it; otherwise, fallback to deep link logic
            return if (historyConfigs.isNotEmpty()) {
                historyConfigs
            } else {
                getInitialStack(deepLink)
            }
        }


        private fun getInitialStack(deepLink: DeepLink): List<Configuration> {
            //Logger.d("getInitialStack -> deepLink -> $deepLink")
            return when (deepLink) {
                is DeepLink.None -> listOf(Configuration.HomeScreenTab()) // Default route
                is DeepLink.Web -> {
                    // Logger.d("Deeplink web -> ${deepLink.path}")
                    listOfNotNull(
                        Configuration.HomeScreenTab(),
                        getConfigForPath(deepLink.path) // Ensure this returns a non-null configuration
                    ).distinct()
                }
            }
        }

        private const val LOGIN_PAGE = "login"
        private const val OTP_VALIDATION_PAGE = "validate-otp"
        private const val REGISTRATION_PAGE = "registration"

        //private const val EXPERT_LIST_PAGE = "expert-list"
        private const val EXPERT_LIST_PAGE = "fetch-consultants"
        private const val EXPERT_DETAILS_PAGE = "expert-details"
        private const val CHOOSE_RECHARGE_PAGE = "choose-recharge"
        private const val RECHARGE_CHECKOUT_PAGE = "recharge-checkout"
        private const val MORE_SCREEN_TAB = "more"

        //private const val HOME_SCREEN_TAB = "home"
        private const val HOME_SCREEN_TAB = ""
        private const val MAIN_SCREEN = "main"
        private const val PRIVACY_POLICY_PAGE = "privacy-policy"
        private const val TERMS_AND_CONDITIONS_PAGE = "terms-and-conditions"
        private const val EDIT_PROFILE_SCREEN = "edit-profile"
        private const val PAYMENT_GATEWAY_PAGE = "payment-gateway"
        private const val START_GATEWAY_PAGE = "payment-gateway"
        private const val RECHARGE_FAILURE_PAGE = "recharge-failure"
        private const val RECHARGE_SUCCESS_PAGE = "recharge-success"
        private const val CHAT_CONSULTATION_INFO_PAGE = "chat-consultation-info"
        private const val DISPLAY_SEARCH_EXPERT_LIST_PAGE = "display-search-expert-list"
        private const val CHAT_CONCERN_PAGE = "chat-concern"
        private const val CALL_CONSULTATION_INFO_PAGE = "call-consultation-info"
        private const val CALL_CONSULTATION_OPTION = "call-consultation-options"
        private const val CALL_PLACE_SUCCESS = "call-place-success"
        private const val CALL_WAITING_SUCCESS_COMPONENT = "call-waiting-success"
        private const val LANDING_PAGE_COMPONENT = "astrology_course"
        private const val TRANSACTION_HISTORY = "transaction-history"
        private const val VOIP_CALL_CONSULTAION = "voip_call_consultation"
        private const val ALL_COUPON_CODE = "all_coupon_code"
        private const val CHAT_HISTORY = "chat-history"
        private const val CHAT_CONVERSATION_SCREEN = "chat-conversation-screen"
        private const val ARTICLE = "article"
        private const val CUSTOM_ORDER = "custom-order"
        private const val CUSTOM_ORDER_DETAILS = "custom-order-details"
        private const val CUSTOM_ORDER_PAYMENT_CONFIRM = "custom-order-payment-confirm"
        private const val CUSTOM_ORDER_PAYMENT_ERROR = "custom-order-payment-error"
        private const val CUSTOM_ORDER_LESSON = "custom-order-lesson"
        private const val COURSES = "course"
        private const val PURCHASE_HISTORY = "purchase-history"
        private const val REFER_AND_EARN = "refer-and-earn"
        private const val POST = "post"
        private const val POST_DETAILS = "post-details"

        private fun getPathForConfig(config: Configuration): String {

            //Logger.d("get Path For Config -> $config")

            val path = when (config) {
                // Authentication
                is Configuration.LoginPage -> "/$LOGIN_PAGE"
                is Configuration.OtpValidationPage -> "/$OTP_VALIDATION_PAGE"
                is Configuration.RegistrationPage -> "/$REGISTRATION_PAGE"

                // Expert related pages
                is Configuration.ExpertListPage -> "/$EXPERT_LIST_PAGE?category=${config.categoryName}&cat_source=${config.categoryId}"
                is Configuration.ExpertDetailsPage -> "/$EXPERT_DETAILS_PAGE?expert=${config.expertId}"

                // Recharge related pages
                is Configuration.ChoseRechargePage -> "/$CHOOSE_RECHARGE_PAGE"
                is Configuration.RechargeCheckout -> "/$RECHARGE_CHECKOUT_PAGE?amount=${config.rechargeData?.amount}&amount_source=${config.rechargeData?.id}&plan=${config.rechargeData?.planName}"// val amount = queryParams?.get("amount") ?: ""
                is Configuration.RechargeFailure -> "/$RECHARGE_FAILURE_PAGE?amount=${config.topUpAmount}"
                is Configuration.RechargeSuccess -> "/$RECHARGE_SUCCESS_PAGE?amount=${config.topUpAmount}"

                // Tabs and main screens
                is Configuration.MoreScreenTab -> "/$MORE_SCREEN_TAB"
                is Configuration.HomeScreenTab -> HOME_SCREEN_TAB
                is Configuration.MainScreen -> "/$MAIN_SCREEN"

                // Legal pages
                is Configuration.PrivacyPolicyPage -> "/$PRIVACY_POLICY_PAGE"
                is Configuration.TermsAndConditions -> "/$TERMS_AND_CONDITIONS_PAGE"

                // Profile related pages
                is Configuration.EditProfileScreen -> "/$EDIT_PROFILE_SCREEN"

                // Payment
                is Configuration.PaymentGateway -> "/$PAYMENT_GATEWAY_PAGE"
//                is Configuration.StartGatewayConfig -> "/$START_GATEWAY_PAGE"

                // Chat consultation feature
//                is Configuration.ChatConsultationInfo -> "/$CHAT_CONSULTATION_INFO_PAGE?expert=${config.expertId}"
                is Configuration.DisplaySearchExpertList -> "/$DISPLAY_SEARCH_EXPERT_LIST_PAGE"
                is Configuration.ChatConcernPage -> "/$CHAT_CONCERN_PAGE?expert=${config.expertId}"
                is Configuration.CallPlaceSuccessval -> "/$CALL_PLACE_SUCCESS?expert_source=${config.expertId}&expert=${config.expertName}&image=${config.expertImage}&skills=${config.expertSkills}"
                is Configuration.CallWaitingSuccessComponent -> "/$CALL_WAITING_SUCCESS_COMPONENT?expert_source=${config.expertId}&expert=${config.expertName}&image=${config.expertImage}&skills=${config.expertSkills}"

                is Configuration.LandingPage -> "/$LANDING_PAGE_COMPONENT"
                //TransactionHistory
                is Configuration.TransactionHistoryComponent -> "/$TRANSACTION_HISTORY"
                is Configuration.VoIPCallConfig -> "/$VOIP_CALL_CONSULTAION"
                is Configuration.AllCouponCode -> "/$ALL_COUPON_CODE"
                is Configuration.ChatHistory -> "/$CHAT_HISTORY"
                is Configuration.ChatConversationScreen -> "/$CHAT_CONVERSATION_SCREEN"
                is Configuration.CustomOrderPage -> "/$CUSTOM_ORDER"
//                is Configuration.CustomOrderDetails -> "/$CUSTOM_ORDER_DETAILS"
                is Configuration.CustomOrderPaymentConfirm -> "/$CUSTOM_ORDER_PAYMENT_CONFIRM"
                is Configuration.CustomOrderLesson -> "/$CUSTOM_ORDER_LESSON/${config.customOrderId}"
                is Configuration.CustomOrderPaymentError -> "/$CUSTOM_ORDER_PAYMENT_ERROR"
                is Configuration.PurchaseHistoryConfig -> "/$PURCHASE_HISTORY"
                is Configuration.ReferAndEarnConfig -> "/$REFER_AND_EARN"
                is Configuration.PostScreenConfig -> "/$POST"
                is Configuration.PostDetailsConfig -> "/$POST_DETAILS/${config.id}"
                is Configuration.CourseHostConfig -> "/$COURSES"
                is Configuration.ArticleDetailPageConfig -> "/$ARTICLE/${config.slug}"
                is Configuration.CustomOrderDetails -> "/$CUSTOM_ORDER_DETAILS"
                Configuration.ArticleHostChild -> "/$ARTICLE"
            }
            return path
        }


        fun parseQueryParameters(query: String?): Map<String, String>? {
            return query?.split("&")
                ?.mapNotNull { param ->
                    val keyValuePair = param.split("=")
                    if (keyValuePair.size == 2) {
                        val key = keyValuePair[0].trim()
                        val value = keyValuePair[1].trim()
                        if (key.isNotEmpty()) key to value else null
                    } else {
                        null
                    }
                }
                ?.toMap()
        }

        private fun getConfigForPath(path: String): Configuration {
            // Logger.d("get Config For Path -> $path")

            val pathWithoutPrefix = path.removePrefix("/")
            val parts = pathWithoutPrefix.split("?", limit = 2)
            val route = parts[0]  // Route path
            val query = parts.getOrNull(1)  // Query parameters
            val pathSegments = pathWithoutPrefix.split("/")

            // Parse query parameters
            val queryParams = parseQueryParameters(query)

             println("pathsegments -> $pathSegments")

            return when {
                // Article Host Page
                pathSegments.firstOrNull() == ARTICLE -> {
                    Configuration.ArticleHostChild
                }

                // Courses Host Page
                pathSegments.firstOrNull() == COURSES -> {
                    Configuration.CourseHostConfig
                }


                // Authentication
                route == LOGIN_PAGE -> Configuration.LoginPage
                route == OTP_VALIDATION_PAGE -> Configuration.LoginPage
                route == REGISTRATION_PAGE -> Configuration.RegistrationPage

                // Expert-related pages
                route == EXPERT_LIST_PAGE -> Configuration.ExpertListPage(
                    categoryName = queryParams?.get("category") ?: "",
                    categoryId = queryParams?.get("cat_source") ?: "",
                    subCategory = null
                )

                route == EXPERT_DETAILS_PAGE || route == CHAT_CONSULTATION_INFO_PAGE ||
                        route == CHAT_CONCERN_PAGE || route == CALL_CONSULTATION_INFO_PAGE ||
                        route == CALL_CONSULTATION_OPTION -> {
                    val expertId = queryParams?.get("expert")?.toIntOrNull() ?: 0
                    Configuration.ExpertDetailsPage(expertId = expertId)
                }

                // Recharge-related pages
                route == CHOOSE_RECHARGE_PAGE -> Configuration.ChoseRechargePage
//                route == RECHARGE_CHECKOUT_PAGE -> Configuration.RechargeCheckout(
//                    rechargeData = RechargeData(
//                        amount = queryParams?.get("amount")?.toIntOrNull() ?: 0,
//                        id = queryParams?.get("amount_source")?.toIntOrNull() ?: 0,
//                        planName = queryParams?.get("plan") ?: "",
//                        cupon = null
//                    )
//                )

                route == RECHARGE_FAILURE_PAGE -> Configuration.RechargeFailure(
                    topUpAmount = queryParams?.get("amount") ?: ""
                )

                route == RECHARGE_SUCCESS_PAGE -> Configuration.RechargeSuccess(
                    topUpAmount = queryParams?.get("amount") ?: ""
                )

                // Main tabs
                route == MORE_SCREEN_TAB -> Configuration.MoreScreenTab
                route == HOME_SCREEN_TAB -> Configuration.HomeScreenTab()
                route == MAIN_SCREEN -> Configuration.MainScreen

                // Legal pages
                route == PRIVACY_POLICY_PAGE -> Configuration.PrivacyPolicyPage
                route == TERMS_AND_CONDITIONS_PAGE -> Configuration.TermsAndConditions

                // Profile and misc pages
                route == EDIT_PROFILE_SCREEN -> Configuration.EditProfileScreen
                route == PAYMENT_GATEWAY_PAGE -> Configuration.ChoseRechargePage
                route == DISPLAY_SEARCH_EXPERT_LIST_PAGE -> Configuration.DisplaySearchExpertList

                // Call consultation
                route == CALL_PLACE_SUCCESS -> Configuration.CallPlaceSuccessval(
                    expertId = queryParams?.get("expert_source") ?: "",
                    expertName = queryParams?.get("expert") ?: "",
                    expertImage = queryParams?.get("image") ?: "",
                    expertSkills = queryParams?.get("skills") ?: "",
                    callType = queryParams?.get("callType") ?: "",
                )

                route == CALL_WAITING_SUCCESS_COMPONENT -> Configuration.CallWaitingSuccessComponent(
                    expertId = queryParams?.get("expert_source") ?: "",
                    expertName = queryParams?.get("expert") ?: "",
                    expertImage = queryParams?.get("image") ?: "",
                    expertSkills = queryParams?.get("skills") ?: "",
                    callType = queryParams?.get("callType") ?: "",
                )

                // Miscellaneous pages
                route == LANDING_PAGE_COMPONENT -> Configuration.LandingPage
                route == TRANSACTION_HISTORY -> Configuration.TransactionHistoryComponent
                route == ALL_COUPON_CODE -> Configuration.AllCouponCode
                route == CHAT_HISTORY -> Configuration.ChoseRechargePage
                route == "home" -> Configuration.LoginPage
                route == CUSTOM_ORDER -> Configuration.CustomOrderPage

                pathSegments.firstOrNull() == CUSTOM_ORDER_LESSON -> {
                    val customOrderId = pathSegments.getOrNull(1)
                    if (customOrderId.isNullOrBlank()) {
                        Configuration.HomeScreenTab()
                    } else {
                        Configuration.CustomOrderLesson(customOrderId)
                    }
                }


                route == CUSTOM_ORDER_LESSON -> Configuration.CustomOrderLesson(
                    customOrderId = queryParams?.get("customOrderId") ?: ""
                )

                route == CUSTOM_ORDER_PAYMENT_ERROR -> Configuration.CustomOrderPaymentError
                route == PURCHASE_HISTORY -> Configuration.PurchaseHistoryConfig

                route == REFER_AND_EARN -> Configuration.ReferAndEarnConfig

                route == ARTICLE -> Configuration.ArticleHostChild

                //Post
                route == POST -> Configuration.PostScreenConfig

                pathSegments.firstOrNull() == POST_DETAILS -> {
                    val post = pathSegments.getOrNull(1)
                    if (post.isNullOrBlank()) {
                        Configuration.PostScreenConfig
                    } else {
                        Configuration.PostDetailsConfig(post)
                    }
                }
//                pathSegments.firstOrNull() == POST_DETAILS -> {
//                    val postString = pathSegments.getOrNull(1)
//                    if (postString.isNullOrBlank()) {
//                        Configuration.PostScreenConfig
//                    } else {
//                        val post = try {
//                            // Deserialize the post string into a PostDataNew object
//                            Json.decodeFromString<PostDataNew>(postString)
//                        } catch (e: Exception) {
//                            null
//                        }
//                        if (post != null) {
//                            Configuration.PostDetailsConfig(post)
//                        } else {
//                            Configuration.PostScreenConfig // Fallback if parsing fails
//                        }
//                    }
//                }


                // Post Details
                //route == POST_DETAILS ->
                // Default case
                else -> {
                    // Logger.d("Unhandled path -> $path")
                    Configuration.HomeScreenTab()
                }
            }
        }
    }

    sealed interface DeepLink {
        data object None : DeepLink
        class Web(val path: String) : DeepLink
    }
}

