
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.window.CanvasBasedWindow
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.essenty.lifecycle.stop
import tta.destinigo.talktoastro.core.navigation_decompose.DefaultRootComponent
import tta.destinigo.talktoastro.core.di.initKoin
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import tta.destinigo.talktoastro.core.navigation_decompose.getPathAndSlug

// Constants for meta tags
const val META_TAG_TITLE = "TalktoAstro - Online Astrology"


@OptIn(ExperimentalComposeUiApi::class, ExperimentalDecomposeApi::class)
fun main() {

    val META_TAG_DESCRIPTION = """
        Unlock your future with a personalized astrology consultation. Talk to astrologer on relationships, 
        career, and life path through birth chart analysis. Book now for clarity and cosmic guidance.
    """.trimIndent()

    // Update Meta Tags
    fun updateMetaTags(title: String, description: String) {
        document.title = title
        val metaDescription = document.querySelector("meta[name='description']")
            ?: document.createElement("meta").apply {
                setAttribute("name", "description")
                document.head?.appendChild(this)
            }
        metaDescription.setAttribute("content", description)
    }

    updateMetaTags(META_TAG_TITLE, META_TAG_DESCRIPTION)

    // Initialize Koin
    initKoin()

    // Lifecycle setup
    val lifecycleRegistry = LifecycleRegistry()
    lifecycleRegistry.attachToDocument()

    // Root component setup
    val rootComponent = DefaultRootComponent(
        componentContext = DefaultComponentContext(lifecycle = lifecycleRegistry),
        deepLink = DefaultRootComponent.DeepLink.Web(path = getPathAndSlug(window.location.href)),
        webHistoryController = DefaultWebHistoryController()
    )

    // Dark theme detection
    val prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

    // Density state setup
    var density by mutableStateOf(Density(window.devicePixelRatio.toFloat()))
    window.addEventListener("resize", { density = Density(window.devicePixelRatio.toFloat()) })

    val skeletonLoader = document.getElementById("skeleton-loader") as? HTMLDivElement
    skeletonLoader?.style?.display = "block"


    // Main window setup
    onWasmReady {

        // Hide skeleton loader
        skeletonLoader?.style?.display = "none"

        CanvasBasedWindow(title = META_TAG_TITLE) {
            CompositionLocalProvider(
                LocalDensity provides density,
                LocalLayoutDirection provides LayoutDirection.Ltr,
            ) {
                App(
                    darkTheme = prefersDarkTheme,
                    dynamicColor = true,
                    rootComponent = rootComponent
                )
            }
        }
    }
}



private fun LifecycleRegistry.attachToDocument() {

    val visibilityState = document.asDynamic().visibilityState as String
    fun onVisibilityChanged() {
        if (visibilityState == "visible") {
            resume()
        } else {
            stop()
        }
    }

    onVisibilityChanged()
        document.addEventListener("visibilitychange", { _: Event ->
        onVisibilityChanged()
    })
}

//fun loadSkiko(): Promise<Unit> {
//    return Promise { resolve, reject ->
//        val script = document.createElement("script") as HTMLScriptElement
//        script.src = "skiko.js"
//        script.onload = { resolve(Unit) }
//        script.onerror = { _: dynamic, _: String, _: Int, _: Int, _: Any? -> reject(Exception("Failed to load skiko.js")) }
//        document.head?.appendChild(script)
//    }
//}

