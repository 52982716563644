import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import tta.destinigo.talktoastro.core.navigation_decompose.RootComponent
import tta.destinigo.talktoastro.feature_article.persentation.article_host.ArticleHostPage
import tta.destinigo.talktoastro.feature_article.persentation.post_page.ArticlePage
import tta.destinigo.talktoastro.feature_course.persentation.course_host.CourseHostPage
import tta.destinigo.talktoastro.feature_authentication.persentation.login_page.loginPage
import tta.destinigo.talktoastro.feature_authentication.persentation.otp_validation_page.OtpValidationPage
import tta.destinigo.talktoastro.feature_authentication.persentation.register_page.RegisterPage
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationBottomSheet
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_consultation_options.CallConsultationOptionScreen
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_waitting_sucess.CallWaitingSuccess
import tta.destinigo.talktoastro.feature_call_consultation.persentation.voip_call_page.VoIPCallConsultationScreen
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_host.ChatHostPage
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.CustomOrderDetailScreen
import tta.destinigo.talktoastro.feature_custom_order.persentation.CustomHostPage
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetails
import tta.destinigo.talktoastro.feature_expert_listing.expert_list_page.ExpertListPage
import tta.destinigo.talktoastro.feature_expert_search.DisplaySearchExpertList
import tta.destinigo.talktoastro.feature_home.presentation.home_tab.HomeScreenTab
import tta.destinigo.talktoastro.feature_post.persentation.PostDetails
import tta.destinigo.talktoastro.feature_post.persentation.PostList
import tta.destinigo.talktoastro.feature_profile.terms_and_conditions_page.TermsAndConditions
import tta.destinigo.talktoastro.feature_profile.MoreScreenTab
import tta.destinigo.talktoastro.feature_profile.chat_history.ChatHistoryScreen
import tta.destinigo.talktoastro.feature_profile.chat_history.widget.ChatConversationComponentUI
import tta.destinigo.talktoastro.feature_profile.edit_profile.EditProfileScreen
import tta.destinigo.talktoastro.feature_profile.privacy_policy.PrivacyPolicyPage
import tta.destinigo.talktoastro.feature_profile.purchase_history.PurchaseHistory
import tta.destinigo.talktoastro.feature_profile.refer_and_earn.ReferEarnScreen
import tta.destinigo.talktoastro.feature_profile.transaction_history.persentation.TransactionHistory
import tta.destinigo.talktoastro.feature_wallet.persentation.razorpay_gateway_new.StartGatewayPage
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_checkout.RechargeCheckout
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_failure.RechargeFailure
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_getway.PaymentGatewayPage
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_pack.ChooseRechargePack
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_payment_success.RechargeSuccess

@Composable
fun App(
    darkTheme: Boolean,
    dynamicColor: Boolean,
    rootComponent: RootComponent,
) {

//    val customShape = Shapes(
//        extraSmall = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),  // Square shape
//        small = RoundedCornerShape(Scale.CORNER_SMALL),      // Slightly rounded corners
//        medium = RoundedCornerShape(Scale.CORNER_MEDIUM),     // More rounded corners
//        large = RoundedCornerShape(Scale.CORNER_LARGE),      // Larger rounded corners
//        extraLarge = RoundedCornerShape(Scale.CORNER_EXTRA_LARGE), // Even larger rounded corners
//    )
    val childStack by rootComponent.stack.subscribeAsState()

    Children(
        stack = childStack,
        animation = stackAnimation()
        //animation = stackAnimation(slide())
    ) { child ->

        when (val instance = child.instance) {

            /*feature_authentication*/
            is RootComponent.Child.LoginPage -> loginPage(instance.component)
            is RootComponent.Child.OtpValidationPage -> OtpValidationPage(instance.component)
            is RootComponent.Child.RegistrationPage -> RegisterPage(instance.component)
//
            is RootComponent.Child.ExpertListPage -> ExpertListPage(instance.component)
            is RootComponent.Child.ExpertDetailsPage -> ExpertDetails(instance.component)
            is RootComponent.Child.HomeScreenTab -> HomeScreenTab(instance.component)
            is RootComponent.Child.MoreScreenTab -> MoreScreenTab(instance.component, true)
            is RootComponent.Child.CourseHostChild -> CourseHostPage(instance.component)
            is RootComponent.Child.ArticleHostChild -> ArticleHostPage(instance.component)
            is RootComponent.Child.ChoseRechargePage -> ChooseRechargePack(instance.component)
            is RootComponent.Child.StartGatewayChild -> StartGatewayPage(instance.component)
            is RootComponent.Child.RechargeCheckout -> RechargeCheckout(instance.component,instance.secondComponent)
            is RootComponent.Child.PaymentGateway -> PaymentGatewayPage(instance.component)
            is RootComponent.Child.RechargeFailure -> RechargeFailure(instance.component)
            is RootComponent.Child.RechargeSuccess -> RechargeSuccess(instance.component)
            is RootComponent.Child.ChatHostChild -> ChatHostPage(instance.component)
            is RootComponent.Child.CallConsultationInfo -> CallConsultationBottomSheet(instance.component)
            is RootComponent.Child.CallConsultationOptions -> CallConsultationOptionScreen(
                instance.component
            )
            is RootComponent.Child.CallPlaceSuccess -> CallWaitingSuccess(instance.component)
            is RootComponent.Child.CallWaitingSuccess -> CallWaitingSuccess(instance.component)
            is RootComponent.Child.VoIPCall -> VoIPCallConsultationScreen(instance.component)
            is RootComponent.Child.ArticleDetailPage -> ArticlePage(instance.component)
            is RootComponent.Child.CustomOrderDetails -> CustomOrderDetailScreen(instance.component)
            is RootComponent.Child.CustomOrderPage -> CustomHostPage(instance.component)
            is RootComponent.Child.TransactionHistory -> TransactionHistory(instance.component)
            is RootComponent.Child.ChatHistory -> ChatHistoryScreen(instance.component)
            is RootComponent.Child.ChatConversationScreen -> ChatConversationComponentUI(instance.component)
            is RootComponent.Child.PrivacyPolicyPage -> PrivacyPolicyPage(instance.component)
            is RootComponent.Child.TermsAndConditions -> TermsAndConditions(instance.component)
            is RootComponent.Child.EditProfileScreen -> EditProfileScreen(instance.component)
            is RootComponent.Child.PurchaseHistory -> PurchaseHistory(instance.component)
            is RootComponent.Child.ReferAndEarn -> ReferEarnScreen(instance.component)
            is RootComponent.Child.PostScreen -> PostList(instance.component)
            is RootComponent.Child.PostDetailsScreenChild -> PostDetails(instance.component)
            is RootComponent.Child.DisplaySearchExpertList ->  DisplaySearchExpertList(instance.component)
        }
    }

}



