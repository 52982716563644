package tta.destinigo.talktoastro.core.navigation_decompose

import kotlinx.serialization.Polymorphic
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_custom_order.data.CustomOrderListData
import tta.destinigo.talktoastro.feature_wallet.persentation.recharge_getway.PaymentGatewayArg
import tta.destinigo.talktoastro.shared.data.SubCategoriesModel
import tta.destinigo.talktoastro.shared.data.CommonExpertInfo
import tta.destinigo.talktoastro.shared.data.RechargeData
import tta.destinigo.talktoastro.shared.data.RequestCategory
import tta.destinigo.talktoastro.shared.extenstion.generateRandomString

@Serializable
sealed class Configuration {
    @Serializable
    data object LoginPage : Configuration()

    @Serializable
    data class OtpValidationPage(
        val mobileNumber: String,
        val mobileCode: String,
        val isWhatsappUpdatesChecked: Boolean
    ) : Configuration()

    @Serializable
    data object RegistrationPage : Configuration()

    @Serializable
    data class ExpertListPage(
        val categoryName: String,
        val categoryId: String,
        val subCategory: RequestCategory?
    ) : Configuration()

    @Serializable
    data class ExpertDetailsPage(val expertId: Int) : Configuration()

    @Serializable
    data object ChoseRechargePage : Configuration()

        @Serializable
        data class RechargeCheckout(
            val rechargeData: RechargeData?,
            val isCustomOrder: Boolean = false,
            val image: String = "",
            val orderHeading: String = ""
        ) : Configuration()

    @Serializable
    data object MoreScreenTab : Configuration()

    //        @Serializable
//        data class HomeScreenTab(val identity: String = generateRandomString()) : Configuration()
    @Serializable
    @Polymorphic
    data class HomeScreenTab(val identity: String = generateRandomString()) : Configuration()

    @Serializable
    data object MainScreen : Configuration()

    @Serializable
    data object PrivacyPolicyPage : Configuration()

    @Serializable
    data object TermsAndConditions : Configuration()

    @Serializable
    data object EditProfileScreen : Configuration()

        @Serializable
        data class PaymentGateway(
            val paymentGatewayArg: PaymentGatewayArg,
            val isCustomOrder: Boolean = false,
            val txnId: String = "",
            val cusOrderId: String = "",
        ) : Configuration()

    @Serializable
    data class RechargeFailure(val topUpAmount: String) : Configuration()

    @Serializable
    data class RechargeSuccess(val topUpAmount: String) : Configuration()

    @Serializable
    data object DisplaySearchExpertList : Configuration()

    @Serializable
    data class ChatConcernPage(
        val expertId: String,
        val expertName: String,
        val expertImage: String,
        val expertSkills: String,
        val chatDuration: String,
        val category: String,
        val chatType: String
    ) : Configuration()

    @Serializable
    data class CallPlaceSuccessval(
        val callType: String,
        val expertId: String,
        val expertName: String,
        val expertImage: String,
        val expertSkills: String
    ) : Configuration()

    @Serializable
    data class CallWaitingSuccessComponent(
        val callType: String,
        val expertId: String,
        val expertName: String,
        val expertImage: String,
        val expertSkills: String
    ) : Configuration()

    @Serializable
    data object LandingPage : Configuration()

    @Serializable
    data object TransactionHistoryComponent : Configuration()

    //All Coupon
    @Serializable
    data object AllCouponCode : Configuration()

    //Chat History
    @Serializable
    data object ChatHistory : Configuration()

    //Chat Conversation Screen
    @Serializable
    data class ChatConversationScreen(
        val chatId: String,
        val name: String,
        val image: String,
        val duration: String
    ) : Configuration()

    @Serializable
    data object CustomOrderPage : Configuration()

    @Serializable
    data class CustomOrderDetails(val data: CustomOrderListData):Configuration()


    @Serializable
    data class VoIPCallConfig(
        val expertInfo: CommonExpertInfo,
        val callDuration: String,
    ) : Configuration()


    @Serializable
    data class CustomOrderPaymentConfirm(val customOrderId: String) : Configuration()

    @Serializable
    data object CustomOrderPaymentError : Configuration()

    @Serializable
    data class CustomOrderLesson(val customOrderId: String) : Configuration()

    @Serializable
    data object CourseHostConfig : Configuration()

    @Serializable
    data object ArticleHostChild : Configuration()

    @Serializable
    data class ArticleDetailPageConfig(val slug: String) : Configuration()

    @Serializable
    data object PurchaseHistoryConfig : Configuration()

    @Serializable
    data object ReferAndEarnConfig : Configuration()

    @Serializable
    data object PostScreenConfig : Configuration()

    @Serializable
    data class PostDetailsConfig(val id: String) : Configuration()

}